export enum OverallRunStatus {
  AnalyzingCode = 'AnalyzingCode',
  DeterminingTechnologies = 'DeterminingTechnologies',
  ScanningCode = 'ScanningCode',
  UnderstandingDependencies = 'UnderstandingDependencies',
  Ready = 'Ready',
}

export const runStages: OverallRunStatus[] = [
  OverallRunStatus.AnalyzingCode,
  OverallRunStatus.DeterminingTechnologies,
  OverallRunStatus.ScanningCode,
  OverallRunStatus.UnderstandingDependencies,
  OverallRunStatus.Ready,
];
